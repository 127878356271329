<template>
    <Outside>
        <Header/>
            <Request model="User" immediate="true" action="passwordReset" ref="sendPasswordResetRef"
                     :form="passwordResetFilter" @success="onSuccessPasswordReset" @error="onErrorPasswordReset"/>
            <div v-if="passwordResetResponse" class="h-full w-full flex flex-col justify-center items-center p-4 text-gray-700">
                <div class="flex flex-col justify-center items-center px-4 bg-gray-200 rounded rounded-md">
                    <div class="w-full p-4 flex flex-col justify-start items-center">
                        <p class="text-lg">Hola <span class="font-semibold">{{ passwordResetResponse.user.name }}</span>!</p>
                        <p>establece aquí tu nueva contraseña.</p>
                    </div>
                    <div class="w-full p-4">
                        <label class="block">
                            <span>Contraseña</span>
                            <input type="password" v-model="form.password" class="form-input mt-1 block w-full text-center tracking-widest leading-none"
                                   placeholder="Contraseña" autofocus ref="passwordRef">
                        </label>
                    </div>
                    <div class="w-full p-4">
                        <label class="block">
                            <span>Confirme contraseña</span>
                            <input type="password" v-model="form.password_confirmation" class="form-input mt-1 block w-full text-center tracking-widest leading-none"
                                   placeholder="Confirme contraseña">
                        </label>
                    </div>
                    <div class="w-full p-4">
                        <Request model="Auth" ref="passwordResetSaveRef" :form="form" action="passwordResetSave" @success="onSuccess" @error="onError"/>
                        <button @click="passwordResetSave" class="button-cust-default w-full">Establecer nueva contraseña</button>
                    </div>
                </div>
            </div>
    </Outside>
</template>

<script>
import {Request} from "@/api/components";
import Outside from "@/pages/outside/shared/Outside";
import Header from "@/pages/outside/shared/Header";

export default {
    name: "PasswordReset",
    components: {
        Request, Outside, Header
    },
    data() {
        return {
            passwordResetResponse : null,
            passwordResetFilter: {
                token: this.$route.params.token,
            },
            form: {
                token: this.$route.params.token,
                password: null,
                password_confirmation: null,
            },
            showForm: false
        };
    },
    methods: {
        onError(errors) {
            this.toastErrors(errors)
        },
        async onSuccess(response) {
            this.$router.push({ name: 'Login' });
            this.$notify(
                { group: "generic", title: 'Info', text: response.success, type: 'info'},
                4000
            );
        },
        passwordResetSave() {
            this.$refs.passwordResetSaveRef.request();
        },
        onSuccessPasswordReset(response) {
            this.passwordResetResponse = response;
        },
        onErrorPasswordReset(errors) {
            this.$router.push({name:'Login'})
            this.toastErrors(errors)
        }
    },
    mounted() {
        /*this.$refs.passwordRef.focus()*/
    }
}
</script>

<style scoped>

</style>